<template>
 
  <div class="galpan">
    <!-- <img class="cover" :src="diary.avatar" alt=""> -->
 
    <div 
      v-for="(week, index) in diary.items_week"    
      :data-id="week.id"
      :ref="el => { if (el) setWeekRef(el, index) }"
      :class="'day_item day_faza_' + week.faza + '  post_day_' + week.id + (activeWeek == week.id ? ' active ' : '')"                                               
      >
      

      <WeekwideViewHarvestTitle
        v-if="week.item_harvest"
        :harvest-name="week.item_harvest?.item_review_seed.item_product.name"
        :harvest-brand="week.item_harvest?.item_review_seed.item_brand.name"
        /> 


      <div class="week" :ref="'week_' + week.id">
        <!-- 📅 -->
         <div class="cal">{{ week.days }}</div>
        <template v-if="week.days">
          Week {{ week.days }}. 
        </template>
        {{ $constants.typeFazaShort[week.faza] ? $t($constants.typeFaza[week.faza]) : '' }}
        <i class="icon-play" v-if="week.is_video"></i>
 
      </div>

      <!-- <div class="photos" :size="week.items_photo.length">
        <div class="photo" v-for="(photo, photo_key) in week.items_photo.slice(0, 6)">
          <img 
            :src="photo.size_m" 
            alt=""
            @click="openGallery(photo.id)"
            >
        </div>
        <div class="more" v-if="week.items_photo.length > 6">
          More photos +{{ week.items_photo.length - 6 }}
        </div>

      </div>


      <div class="dots">
        <div class="dot" v-for="(photo, photo_key) in week.items_photo.slice(0, 6)">
          
        </div> 
      </div> -->

      <!-- {{ week }} -->





      <DiarywideViewGalleryWeeksSlider 
        :week="week" 
        :diary="diary"
        />


      <!-- <WeekwideViewGalleryComment :week="week" :user="diary.item_user"/> -->



      <WeekwideViewCommentSetupBig
        v-if="week.faza == 2"
        :setup="week.props"     
        /> 
      <WeekwideViewCommentSetup
        v-else
        :setup="week.props"     
        /> 

      <WeekwideViewNutrientsSetup
        :nutrients="week.item_germination?.items_nutrient || week.item_vegetation?.items_nutrient || week.item_flowering?.items_nutrient"
        /> 




      <WeekwideViewReview
        :data="week.item_harvest"
        /> 

      <WeekwideViewComment 
        :week="week" 
        :weeks="diary.items_week"
        :user="diary.item_user"
        :comments="props.comments"
        />


      
      <WeekwideViewGermMethod :item-method="week.item_germination?.germ_method" />

      <WeekwideViewMethods :items-method="week.item_vegetation?.items_method || week.item_flowering?.items_method" />
 

    </div>



    
  </div>


  <!-- <GeneralLightboxVertical 
      v-if="lightboxShow"
      :photo-id="lightboxId" 
      :photos="lightboxPhotos" 
      @close="lightboxShow = false" 
      /> -->
  
</template>

<script setup>

import { useIntersectionObserver, watchArray } from '@vueuse/core'

var props = defineProps({
  diary: {
    type: Object,
    required: true
  }, 
  activeWeek: {
    type: Object,
    required: false
  },
  comments: {
    type: Object,
    required: false
  }
})
const emits = defineEmits(['interactWeek'])
const weekRefs = ref([])
const observers = []
const isObserverDisabled = ref(false);
const isScrolledViewWeek = ref(false);

var lightboxShow = ref(false);
var lightboxId = ref(null);

const setWeekRef = (el, index) => {
  weekRefs.value[index] = el
}


const lightboxPhotos = computed(() => {
  var items = [];


  for(var i of props.diary.items_week){
    for(var p of i.items_photo){
      let photo = {};
      if(p.is_video){
        photo.type = 'iframe';
        photo.iframe = '/video/' + p.video_id;
      }          
      photo.id = p.id;
      photo.width = p.width;
      photo.height = p.height;
      photo.base = p.size_big;
      photo.zoom = p.size_big;
      photo.caption = p.comment;
      items.push(photo);        
    }
  }
  return items;

  // for(var i of props.data.items_photo){
  //   let photo = {};
  //   if(i.is_video){
  //     photo.type = 'iframe';
  //     photo.iframe = '/video/' + i.video_id;
  //   }          
  //   photo.base = i.size_big;
  //   photo.zoom = i.size_big;
  //   photo.caption = i.comment;
  //   items.push(photo);        
  // }
  // return items;
})

const openGallery = function(key){
  lightboxShow.value = true;
  lightboxId.value = key;
}


onMounted(() => {
  
  weekRefs.value.forEach((weekEl, index) => {
    const observer = useIntersectionObserver(
      ref(weekEl),
      ([{isIntersecting}]) => {
        if (isObserverDisabled.value) return; 
        if (isIntersecting) {
          console.log('infinity scroll event for week', index)
          emits('interactWeek', props.diary.items_week[index])
          isScrolledViewWeek.value = true;
          setTimeout(() => {
            isScrolledViewWeek.value = false;
          }, 1000);
        }
      },
      { 
        root: null, 
        // rootMargin: '-15% 0px -15% 0px', 
        rootMargin: '0% 0px -80% 0px', 
        threshold: 0
      }
    )
    observers.push(observer)
  })
})

onBeforeUnmount(() => {
  observers.forEach(observer => observer.stop())
})

 
watchArray([props], ([new_props]) => {
  console.log('activeWeek gallery', new_props.activeWeek);
  isObserverDisabled.value = true; 

  props.diary.items_week.forEach((w,i) => {
    
    if(isScrolledViewWeek.value) return;

    if(w.id == new_props.activeWeek.id){
      console.log('watch props weeks', w,i);
      // weekRefs.value[i].scrollIntoView({ behavior: 'smooth' });

      const elementRect = weekRefs.value[i].getBoundingClientRect();
      const elementTop = elementRect.top + window.pageYOffset;

      // Вычитаем желаемые пикселы и скроллим к этому месту
      window.scrollTo({
        top: elementTop - 50, // вычитаем 40 пикселей
        behavior: 'smooth'
      });

    }
  });

  setTimeout(() => {
    isObserverDisabled.value = false; 
  }, 1000);

}, {deep: true})

</script>

<style scoped>
 
.galpan{

  display: flex;
    flex-direction: column;
    gap: 3rem;
    position: relative;
    width: 100%;
    margin-bottom: 7rem;
}
.cover{
  width: 100%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: 4/3;
    border-radius: 5px;
}

/* week */

.week-old{
  font-weight: bold;
  padding-bottom: 4px;
  z-index: 1;
  background-color: #ffffffe6;
  border-radius: 5px;
  padding: 4px 12px;
  position: sticky;
  top: 60px;
  width: fit-content;
  margin-top: 12px;
  margin-bottom: -40px;
  font-size: 1rem;
  margin-left: 10px;
}
.week{
  font-weight: bold;
    padding-bottom: 4px;
    z-index: 1;
    background-color: #ffffffe6;
    border-radius: 5px;
    padding: 4px 12px;
    position: sticky;
    top: 60px;
    width: -moz-fit-content;
    width: fit-content;
    /* margin-top: 12px; */
    margin-bottom: 5px;
    font-size: 1rem;
    margin-left: -12px;
}
.is-modal .week{
  top:10px;
}
/* photos */

.photos{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  position: relative;
}
.photos .more{
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #ffffffe6;
  padding: 4px 8px;
  border-radius: 5px;
  cursor: pointer;
  /* color: var(--un-text-color-gray); */
  /* font-weight: bold; */
  z-index: 1;
  margin-right: 10px;
  margin-bottom: 10px;
  transition: 0.3s;
  pointer-events: none;
}
.photos .photo{
  display: flex;
  flex-wrap: wrap;
  aspect-ratio: 4/3;
  flex: 1 1 calc(100%/3 - 4px);
  /* remove id need 1 big photo */
  max-width: calc(100%/3 - 4px);
}
.photos .photo img{
  width:100%;
  height:100%;
  object-fit: cover;
  border-radius: 5px;
}

.photos img:only-child {
  flex-basis: 100%;
}

/* Если два изображения */
/* .photos .photo:nth-last-child(2):first-child,
.photos .photo:nth-last-child(2):last-child {
    flex-basis: calc(100%/3 - 4px);
} */

@container pb (max-width: 600px) {
  .photos{
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    flex-wrap: nowrap;
    margin-left: -20px;
    width: calc(100% + 40px);
    gap: 0;
  }
  .photos::-webkit-scrollbar {
    display: none;
  }
  .photos .photo{
    aspect-ratio: 1 / 1;
    flex-shrink: 0;
    width: 100% !important;
    flex: none;
    max-width: none;
    align-items: center;
    contain: layout;
    justify-content: center;
    position: relative;
    scroll-snap-align: center;
    scroll-snap-align: start;
    scroll-snap-stop: always;
  }
  .photos .photo img{
    aspect-ratio: 1 / 1;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    border-radius: 0;
  } 
  .week{

  }
}


/* SIZES GALLERY */
@container pb (min-width: 600px) {
.photos[size="1"] .photo{
  max-width: 100%;
  flex: 1 1 100%;
}
.photos[size="2"] .photo{
  max-width: calc(100%/2 - 4px);
  flex: 1 1 calc(100%/2 - 4px);
}
.photos[size="3"] .photo{
  max-width: calc(100%/3 - 4px);
  flex: 1 1 calc(100%/3 - 4px);
}
.photos[size="4"] .photo{
  max-width: calc(100%/2 - 4px);
  flex: 1 1 calc(100%/2 - 4px);
}
.photos[size="5"] .photo:nth-child(1),
.photos[size="5"] .photo:nth-child(1),
.photos[size="5"] .photo:nth-child(3){
  max-width: calc(100%/3 - 4px);
  flex: 1 1 calc(100%/3 - 4px);
}
.photos[size="5"] .photo:nth-child(4),
.photos[size="5"] .photo:nth-child(5){
  max-width: calc(100%/2 - 4px);
  flex: 1 1 calc(100%/2 - 4px);
}
}



.dots{
  display: flex;
  gap: 4px;
  position: relative;
  margin-top: -20px;
  margin-bottom: 20px;
  
  justify-content: center;
  z-index: 1;
}

.dot{
  width: 7px;
  height: 7px;
  background-color: rgb(159, 159, 159);
  border-radius: 50%;
}


@container pb (min-width: 600px) {
  .dots{
    display: none;
  }
}


/* calendar */

.cal{
  font-size: 0.6rem;
    font-weight: bold;
    color: var(--un-text-color-gray);
    background-image: url(/images/calendar/1.svg);
    aspect-ratio: 1 / 1;
    width: 22px;
    height: 22px;
    display: inline-block;
    text-align: center;
    line-height: 29px;
}

</style>
