<template>
  <div class="report_header">
    <div class="report_info">
      <div class="report_name">
        <h1>
          {{ props.data.name }}

          <template v-if="props.data.is_active">
            <i :title="$constants.growScoreText['smile'] ? $t($constants.growScoreText['smile']) : ''" v-if="props.data.n_progress == 3" class="icon-smile-good prog_3"></i>
            <i :title="$constants.growScoreText['meh'] ? $t($constants.growScoreText['meh']) : ''" v-else-if="props.data.n_progress == 2" class="icon-smile-medium prog_2"></i>
            <i :title="$constants.growScoreText['frown'] ? $t($constants.growScoreText['frown']) : ''" v-else class="icon-smile-bad prog_1"></i>
          </template>

          <template v-else-if="props.data.is_moderate">
            <i title="Diary was abandoned more than 3 weeks ago" class="icon-rip rip"></i>
          </template>
        </h1>
      </div>

      <div class="report_statistic">
        <div class="user" v-if="props.data.item_user">
          <NuxtLink class="avatar" :to="props.data.item_user.link">
            <img :src="props.data.item_user.avatar_little" srcset="" data-srcset="" height="18" width="18" data-ratio="18-18" :alt="props.data.item_user.name">
          </NuxtLink>

          <div :class="'user_range hidden u_range_' + props.data.item_user.status"></div>

          <NuxtLink class="name" :to="props.data.item_user.link">
            {{ props.data.item_user.name }}
          </NuxtLink>
        </div>

        <div class="row_stat approved" v-if="approvedByBrands?.length">
          <i class="icon-success-round" :title="$t('diary_info_approved')"></i>
          {{ $t('diary_info_approved') }} {{ approvedByBrands }}
        </div>

        <div class="row_stat" v-if="props.data.cnt_followers">
          <i class="icon-users" :title="$t('diary_info_followers')"></i>
          {{ props.data.cnt_followers }}
        </div>

        <div class="row_stat" v-if="props.data.cnt_likes">
          <i class="icon-leaf" :title="$t('diary_info_likes')"></i>
          {{ props.data.cnt_likes }}
        </div>

        <div class="row_stat" v-if="props.data.cnt_comments">
          <i class="icon-comment" :title="$t('diary_info_comments')"></i>
          {{ props.data.cnt_comments }}
        </div>

        <div class="row_stat" v-if="props.data.cnt_views">
          <i class="icon-eye" :title="$t('diary_info_views')"></i>
          {{ props.data.cnt_views }}
        </div>

        <div class="row_stat update">
          <i class="icon-refresh" :title="$t('diary_info_updated')"></i>
          {{ $dayjs.utc(props.data.add_last_day).fromNow() }}
        </div>

        <div class="row_stat approving" v-if="props.data.max_faza >= 2 && useAuth().getAccess('approve_diaries') && allowApprovingBrand" @click="approving">
          <template v-if="isApproved">
            <i class="icon-remove" :title="$t('diary_info_unapprove_diary')"></i>
            {{ $t('diary_info_unapprove_diary') }}
          </template>

          <template v-else="isApproved">
            <i class="icon-check" :title="$t('diary_info_approve_diary')"></i>
            {{ $t('diary_info_approve_diary') }}
          </template>
        </div>
      </div>
    </div>

    <div class="report_btn">
      <DiaryViewActionsEdit
        :data="props.data"
        :diary="props.data.id"
        :diaryhidden="props.data.is_hidden ? true : false"
        :owner="props.data.item_user?.id"
        :isfollow="props.data?.addon?.follow"
        :followers="props.data.cnt_followers"
        @follow="$emit('follow')"
        @unfollow="$emit('unfollow')"
        @removediary="$emit('removediary')"
        @unpublishdiary="$emit('unpublishdiary')"
        @publishdiary="$emit('publishdiary')"
      />
    </div>
  </div>
</template>

<script setup>
const emits = defineEmits([
  'approve',
  'unapprove',
])

const props = defineProps({
  data: {
    type: Object,
    required: true
  },
});

const isApproved = computed(() => {
  if (!props.data?.approved?.length) return

  return props.data.approved.includes(useAuth().getAccess('approve_diaries_brand'))
})

const approvedByBrands = computed(() => {
  if (!props.data?.approved?.length || !props.data?.items_seed?.length) return

  var brands = []

  props.data.items_seed.forEach((item) => {
    if (props.data.approved.indexOf(item.item_brand.id) >= 0) {
      brands.push(item.item_brand.name);
    }
  })

  //uniq array
  brands = [...new Set(brands)]

  return brands.join(', ')
})

const allowApprovingBrand = computed(() => {
  if (!useAuth().getAccess('approve_diaries_brand') || !props.data?.items_seed?.length) return

  return props.data.items_seed.some((item) => {
    return item.item_brand.id === useAuth().getAccess('approve_diaries_brand')
  })
})

const approving = () => {
  if (isApproved.value) {
    emits('unapprove', 0, useAuth().getAccess('approve_diaries_brand'))
  } else {
    emits('approve', 1, useAuth().getAccess('approve_diaries_brand'))
  }
}
</script>

<style scoped>




.report_header{
  display: flex;
  width: 100%;
}

.report_header .report_info{
  flex-grow: 1;
}
.report_header .report_info .report_name{}
.report_header .report_info .report_name h1{
  display: inline-block;
  margin-bottom: 5px;
  margin-top: 0;
}
.report_header .report_info .report_name .prog_3{
  color: var(--un-firm);
}
.report_header .report_info .report_name .prog_2{
  color: #dd8f00;
}
.report_header .report_info .report_name .prog_1{
  color: red;
}
.report_header .report_info .report_name .rip_diary{
  margin-left: 10px;
}
.report_header .report_info .report_name .score_diary{
  display: inline-block;
  border-radius: 43px;
  color: var(--un-primary-back-color);
  font-size: 1.5rem;
  height: 23px;
  text-align: center;
  width: 23px;
  line-height: 1.7rem;
  font-weight: 800;
}
.report_header .report_info .report_statistic{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  flex-wrap: wrap;
  gap: 0.3rem 0.7rem;
}
.report_header .report_info .report_statistic .user{
  display: flex;
  align-items: center;
}
.report_header .report_info .report_statistic .user .avatar{
  display: inline-block;
  margin-right: 7px;
  height: 18px;
  aspect-ratio: 1/1;
}
.report_header .report_info .report_statistic .user .avatar img{
  border-radius: 50px;
  max-height: 18px;
}
.report_header .report_info .report_statistic .user .user_range{}
.report_header .report_info .report_statistic .user .name{
  display: inline-block;
  color: var(--gd-text-gray-color);
  font-weight: bold;
}
.report_header .report_info .report_statistic .row_stat.approved{
  color: green;
}
.report_header .report_info .report_statistic .row_stat.approving{
  cursor: pointer;
  font-weight: bold;
}
.report_header .report_info .report_statistic .row_stat{
  white-space: nowrap;
}
.report_header .report_info .report_statistic .row_stat .icon-leaf-like{
  font-size: 1.4rem;
  vertical-align: sub;
}
.report_header .report_info .report_statistic .row_stat .icon{
  font-size: 1.1rem;
}

.report_header .report_btn{
  text-align: right;
  flex-grow: 1;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  align-content: flex-end;
  flex-direction: row;
  justify-content: flex-end;
}
.report_header .report_info .report_statistic .row_stat.approved{
  white-space: nowrap;
}
.report_header .report_info .report_statistic .row_stat.approved .list{
  font-weight: bold;
  color: var(--un-text-color);
}

</style>