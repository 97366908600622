<template>

<!-- {{data}} -->
  <div v-if="props.data" class="setup" @click="toggleReview">

    

    <div class="ttl">
      

      {{ props.data.item_review_seed.item_product.name }} by
      {{ props.data.item_review_seed.item_brand.name }} 


      <div class="stars">
        <i class="icon-star" 
          v-if="props.data.item_review_seed.n_general > 0"
          v-for="n in Math.round(props.data.item_review_seed.n_general)"
          ></i>
        <i class="icon-star-empty"
          v-if="props.data.item_review_seed.n_general <= 10"
          v-for="n in Math.round(10 - props.data.item_review_seed.n_general)"
          ></i> 
          
        {{ props.data.item_review_seed.n_general }} / 10

      </div>

    </div>
    
    <div class="text text-ellipsis-3" v-if="props.data.item_review_seed.text">
      {{ props.data.item_review_seed.text }}
    </div>
 

  </div>

 
  
</template>

<script setup>

import { inject } from 'vue';
  
const { t } = useI18n();
const { $constants, $convert } = useNuxtApp();

// const emits = defineEmits(['click.comment'])

const props = defineProps({
  data: {
    type: Object,
    required: true
  },
})


const actionsToggleReviewEvent = inject('actionsToggleReviewEvent');
const toggleReview = () => {
  actionsToggleReviewEvent({  });
};

  

</script>




<style scoped>
 
 .week_comment{
  display: flex;
  gap: 5px;
  flex-direction: column;
  padding: 10px 0;
}
.date{
  font-size: 0.8rem;
  color: #999;
}

.setup{
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow-x: auto;
  margin-top: 10px;
  background-color: var(--un-background-color-gray);
  border-radius: 10px;
  padding: 0.6rem;
  cursor: pointer;
}

.setup .ttl{
  font-weight: 600;
}
.setup .ttl .stars{
/* font-size: 1rem; */
font-weight: normal;
}
.setup .ttl .stars i{
  color: #f1c40f;
}
.setup .list{
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}
.setup .list .item{
  display: flex;
  align-items: center;
  gap: 5px;
}

.setup .list .item .ava{
  width: 20px;
  height: 20px;
  object-fit: cover;
}

.setup .list .item .name{
  font-weight: bold;
}

.setup .list .item .vl{
  font-size: 0.8rem;
  color: #999;
}

   
@container pb (min-width: 600px) {
 
  
}



</style>
