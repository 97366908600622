<template>


  <div class="ihar">
    <div class="img-harvest"></div>

    <div class="inf">
      <div class="name">
        Harvested
      </div>
      <div class="br">
        {{ harvestBrand }} - {{ harvestName }}
      </div>
    </div>

  </div>

  
</template>

<script setup>


  
const { t } = useI18n();
const { $constants, $convert } = useNuxtApp();



const props = defineProps({
  harvestName: {
    type: String,
    required: ''
  },
  harvestBrand: {
    type: String,
    required: ''
  }
}) 

</script>




<style scoped>

.ihar{
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 3rem;
  text-align: center;
  position: relative;
}
.ihar::after,
.ihar::before{
  content: '';
  width: calc(100% / 2 - 50px);
  height: 1px;
  border-bottom: 2px #b6d06680 solid;
  position: absolute;
  top: 50px;
  left: 0;
}
.ihar::before{
  left: auto;
  right: 0;
}
.info{
  z-index: 1;
}
.name{
  font-weight: bold;
  font-size: 1.1rem;
}
.br{}

.img-harvest{
  background-image: url('/images/diary_finish.svg');
  background-size: contain;
  width: 90px;
  aspect-ratio: 1/1;
  z-index: 1;
}

@container pb (min-width: 600px) {

  /* .setup{
    display: none!important;
  } */

  
}



</style>
