<template>


  <div class="overlay-box">

    <div class="overlay-box-dimmer" @click="close"></div>

    <div class="comments">
      
      <div class="head">
        <div class="title">
          {{ title }}
        </div>
        <div class="acts">        
          <div class="act" @click="close">
            <i class="icon-close-thin"></i>
          </div>
        </div>
      </div>

      
      
      <div class="cont" v-if="week.item_harvest">     
        

       
        <WeekwideViewHarvestSeedReview 
          :data="week" 
          /> 
 
 
        <WeekwideViewEffects 
          :taste="week.item_harvest.item_review_seed.items_taste"   
          :positive="week.item_harvest.item_review_seed.items_effect_positive" 
          :negative="week.item_harvest.item_review_seed.items_effect_negative" 
          :medical="week.item_harvest.item_review_seed.items_effect_medical" 
          :indica="week.item_harvest.item_review_seed.props.indica" 
          />


        <WeekwideViewHarvestNutrientReview 
          :data="week.item_harvest.items_nutrient"
          />

      </div>
    </div>
  </div>

</template>


<script setup>
  
const props = defineProps({
  week: {
    type: Object,
    required: false
  },
  title: {
    type: String,
    required: false
  }
});
 

const emits = defineEmits(['close'])
 
const close = function(){
  emits('close');
}




</script>
   




<style scoped>
  
  .comments{
    height: 100%;
    max-height: 600px;
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    max-height: inherit;
    z-index: 1;
    background-color: var(--un-background-color);
    padding: 1rem;
    container: pb / inline-size;
    box-shadow: 0 0 20px #00000012;
    border-radius: 5px 5px 0 0;
  } 
  .cont{
    max-width: 975px;
    width: 100%;
    margin: 0 auto;
    overscroll-behavior: contain;
    overflow-y: auto;
  }
  .head {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 1rem;
  }
  .head .acts{
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .head .acts .act{
    width: 40px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .head .acts .act:deep(i),
  .head .acts .act i{
    font-size: 1rem;
  }
  .head .title{
    font-size: 1.1rem;
    font-weight: bold; 
  }
  .head .title .cnt{
    font-weight: normal;
  }

  .settings_comments{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0rem 1rem;
    flex-wrap: wrap;
    margin-bottom: 1rem;
  }
   
  .toggler{
    display: flex;
    align-items: center;
    gap: 5px;
  }
.empty_comments{
 display: flex;
 align-items: center;
 justify-content: center;
 min-height: 70px;
 width: 100%;
 color: var(--gd-text-gray-color);
}

.overlay-box{
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: var(--un-background-color); */
  z-index: 999999;  
  max-height: 70vh;
  /* padding: 1rem; */ 

  display: flex;
  flex-direction: column;
}
/* dimmer */
.overlay-box-dimmer{
  content: '';
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #00000059;
  /* z-index: 23333; */
}
  @container pb (max-width: 768px) {
    .settings_comments{
      justify-content: space-between;
    }
  }

</style>
