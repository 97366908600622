<template>

  <div class="methods" v-if="props.itemMethod?.id">

    <div class="dot-ttl">
        Used method
    </div>
    
    <div class="lst">
          
      <div 
        class="method">
        <i :class="'ic ic-germ-method-' + props.itemMethod.id"></i>
        <div class="info">
          <div class="name">
            {{ $constants.typeGermMethodShort[props.itemMethod.id] ? $t($constants.typeGermMethodShort[props.itemMethod.id]) : '' }}
          </div>
          <div class="title">{{ $t('universal_grow_germ_method_title') }} </div>
        </div>
      </div>

    </div>
 
  </div>

  
</template>

<script setup>
 
 const props = defineProps({
    itemMethod: {
      type: Object,
      default: {},
    },
  })

  
  
</script>

<style scoped>
 
 .methods {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
 }
 .methods .dot{
    height: 8px;
    width: 8px;
    background-color: var(--un-background-color-gray-dark);
    border-radius: 50%;
 }
.methods .lst {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  /* height: 50px; */
  align-items: flex-start;
  position: relative;
  /* padding-right: 30px; */
  /* margin-top: 10px; */
  justify-content: center;
}

.methods .method {
  cursor: help;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 10px;
}

.methods .method .info {
  display: flex;
  flex-direction: column;
  /*width: calc(100% - 46px);*/
}

.methods .method .info .name {
  color: var(--un-text-color);
  /*max-width: 120px;*/
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.methods .method .info .title {
  color: gray;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /*max-width: 120px;*/
}


.ic{
  display: inline-block;
  width: 40px;
  height: 40px;
  background-size: cover;
  background-repeat: no-repeat;
  margin-right: 10px;
}

.ic-germ-method-1{ background-image: url(/images/ico_germ_method/paper.svg); }
.ic-germ-method-2{ background-image: url(/images/ico_germ_method/glass.svg); }
.ic-germ-method-3{ background-image: url(/images/ico_germ_method/chamber.svg); }
.ic-germ-method-4{ background-image: url(/images/ico_germ_method/rockwool.svg); }
.ic-germ-method-5{ background-image: url(/images/ico_germ_method/peat.svg); }
.ic-germ-method-6{ background-image: url(/images/ico_germ_method/substrate.svg); }
.ic-germ-method-7{ background-image: url(/images/ico_germ_method/other.svg); }



.dot-ttl{
  font-weight: bold;
  color: var(--un-text-color-gray);
  margin-bottom: 10px;
}
.dot-ttl:after{
  content: '';
  display: inline-block;
  width: 5px;
  height: 5px;
  background-color: var(--un-text-color-gray);
  border-radius: 10px;
  vertical-align: middle;
  margin-left: 10px;
}
.dot-ttl:before{
  content: '';
  display: inline-block;
  width: 5px;
  height: 5px;
  background-color: var(--un-text-color-gray);
  border-radius: 10px;
  vertical-align: middle;
  margin-right: 10px;
}

</style>
